import { IFieldFiltering, IFilterValues, IView } from '@wf-mfe-maestro/api';

import { ReadonlyField } from '../../state/fieldState';

export const polishFSG = (view: IView, fieldMap: Record<string, ReadonlyField>) => {
  const { filter, sorting, grouping } = view;

  return {
    filter: correctFiltering(filter, fieldMap),
    sorting: correctSorting(sorting, fieldMap),
    grouping: correctGrouping(grouping, fieldMap),
  };
};

export const correctSorting = (
  sorting: IView['sorting'],
  fieldsMap: Record<string, ReadonlyField>
) => sorting && sorting.filter((sortItem) => fieldsMap[sortItem.fieldId]);

const correctFilterValuesArray = (
  filterValuesArray: IFilterValues[],
  fieldsMap: Record<string, ReadonlyField>
) => {
  const cleanedFilterValuesArray = [];

  for (const filterValues of filterValuesArray) {
    const correctFilterValues: Record<string, IFilterValues[keyof IFilterValues]> = {};

    for (const key in filterValues) {
      if (['$and', '$or'].includes(key)) {
        const cleanedFilterValues = correctFilterValuesArray(
          filterValues[key] as unknown as IFilterValues[],
          fieldsMap
        );
        if (cleanedFilterValues.length && typeof key === 'string') {
          correctFilterValues[key] =
            cleanedFilterValues as unknown as IFilterValues[keyof IFilterValues];
        }
        continue;
      }
      // since predefined filters has _id identifier we should include it in correctFilterValues
      if (key === '_id') {
        correctFilterValues[key] = filterValues[key];
      } else {
        // since long-text field key is id.content, we need to remove .content before checking in fieldsMap
        const correctKey = key.replace('.content', '');
        if (fieldsMap[correctKey]) {
          correctFilterValues[key] = filterValues[key];
        }
      }
    }

    if (Object.keys(correctFilterValues).length) {
      cleanedFilterValuesArray.push(correctFilterValues);
    }
  }
  return cleanedFilterValuesArray;
};

const correctFilterItem = (
  filterItem: IFieldFiltering,
  fieldsMap: Record<string, ReadonlyField>
) => {
  const cleanedFilterItem: IFieldFiltering = {};

  for (const logicOperator in filterItem) {
    const filterValuesArray = filterItem[logicOperator];
    const cleanedFilterValuesArray = correctFilterValuesArray(filterValuesArray, fieldsMap);

    if (cleanedFilterValuesArray.length) {
      cleanedFilterItem[logicOperator] = cleanedFilterValuesArray;
    }
  }
  return cleanedFilterItem;
};

export const correctFiltering = (
  filter: IView['filter'],
  fieldsMap: Record<string, ReadonlyField>
) => {
  if (!filter?.length) {
    return filter;
  }

  const cleanedFilter = [];

  for (const filterItem of filter) {
    const cleanedFilterItem = correctFilterItem(filterItem, fieldsMap);
    if (Object.keys(cleanedFilterItem).length) {
      cleanedFilter.push(cleanedFilterItem);
    }
  }

  return cleanedFilter;
};

export const correctGrouping = (
  grouping: IView['grouping'],
  fieldsMap: Record<string, ReadonlyField>
) => grouping && grouping.filter((groupItem) => fieldsMap[groupItem]);
