/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from '@store';
import { useEffect, useMemo } from 'react';
import { useIsTreatmentEnabled } from '@wf-mfe/toggles';
import { FieldType } from '@wf-mfe-maestro/api';

import { availableFieldTypesSubject$ } from '../../state';
import { useForceUpdate } from '../useForceUpdate/useForceUpdate';

export const useAvailableFieldTypesRxJS = () => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const subscription = availableFieldTypesSubject$.subscribe(forceUpdate);
    return () => subscription.unsubscribe();
  }, [forceUpdate]);

  return {
    availableFieldTypes: availableFieldTypesSubject$.getValue(),
  };
};

export const useAvailableFieldTypesRedux = () => {
  const isObjectFieldTypeSupportEnabled = useIsTreatmentEnabled(
    'wf-planning-object-field-type-support'
  );
  const availableFieldTypes = useSelector((state) => state.availableFieldTypes);
  const filteredFieldTypes = useMemo(
    () =>
      availableFieldTypes.filter(
        (fieldType) => isObjectFieldTypeSupportEnabled || fieldType.type !== FieldType.OBJECT
      ),
    [availableFieldTypes, isObjectFieldTypeSupportEnabled]
  );
  return { availableFieldTypes: filteredFieldTypes };
};

export const useAvailableFieldTypes = () => {
  const isMaestroViewServiceReduxEnabled = useIsTreatmentEnabled('maestro-view-service-redux');

  // This break rule of hooks, but it's a temporary solution
  if (isMaestroViewServiceReduxEnabled) {
    return useAvailableFieldTypesRedux();
  }
  return useAvailableFieldTypesRxJS();
};
