import { ITableViewMetadata } from '@wf-mfe-maestro/api';
import { ReadonlyField } from 'state';

import { correctFiltering } from '../polishFSG';

export const correctColoring = (
  coloring: ITableViewMetadata['coloring'],
  fieldsMap: Record<string, ReadonlyField>
) => {
  if (!coloring) {
    return coloring;
  }

  return {
    ...coloring,
    record: {
      ...coloring.record,
      filterColoringRules: coloring.record.filterColoringRules
        .map(({ appliedFilters, ...rest }) => ({
          ...rest,
          appliedFilters: correctFiltering(appliedFilters, fieldsMap),
        }))
        .filter(({ appliedFilters }) => appliedFilters.length),
    },
  };
};
