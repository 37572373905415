import { MessageCallback, useRtbeEvents } from '@wf-mfe/event-stream';
import { getIsInGenStudio } from '@wf-mfe-maestro/navigation';
import { useCallback, useMemo } from 'react';
import { isCalculatedField } from '@wf-mfe-maestro/utils';
import { useCurrentUser } from '@wf-mfe-maestro/auth';
import { getAdobeIOConfigForGenstudio } from '@wf-mfe-maestro/api';

import { isSameSession } from '../../utils/isSameSession';
import { getFieldById } from '../../stateManagement';
import {
  IRTBERecordCreateMessage,
  IRTBERecordCreatedMessage,
  IRTBERecordDeleteMessage,
  IRTBERecordMessage,
  IRTBERecordRestoredMessage,
  IRTBERecordUpdateMessage,
} from '../../types/rtbe';
import { useRTBEQueue } from './useRTBEQueue';

const RTBE_TEAM = 'maestro';

export const isCreatedOperation = (
  message: IRTBERecordMessage
): message is IRTBERecordCreatedMessage => message.operation === 'CREATED';
export const isRestoredOperation = (
  message: IRTBERecordMessage
): message is IRTBERecordRestoredMessage => message.operation === 'RESTORED';
export const isUpdatedOperation = (
  message: IRTBERecordMessage
): message is IRTBERecordUpdateMessage => message.operation === 'UPDATED';

export const isDeleteOperation = (
  message: IRTBERecordMessage
): message is IRTBERecordDeleteMessage => message.operation === 'DELETED';

const hasCalculatedField = (data: IRTBERecordUpdateMessage['data']) => {
  if (!data.data) {
    return false;
  }
  return Object.keys(data.data).some((fieldId) => {
    const field = getFieldById(fieldId);
    return field && isCalculatedField(field);
  });
};

export const useRecordsRTBE = ({
  recordTypeId,
  handleLiveUpdates,
  viewName,
  debounceTime,
}: {
  recordTypeId: string;
  handleLiveUpdates: (
    addPayloads: IRTBERecordCreateMessage[],
    updatePayloads: IRTBERecordUpdateMessage[],
    deletePayloads: IRTBERecordDeleteMessage[]
  ) => void;
  viewName: string;
  debounceTime?: number;
}) => {
  const currentUser = useCurrentUser();
  const handleLiveUpdatesLocal = useCallback(
    (
      addPayloads: IRTBERecordCreateMessage[],
      updatePayloads: IRTBERecordUpdateMessage[],
      deletePayloads: IRTBERecordDeleteMessage[]
    ) => {
      const updatePayloadsFiltered = updatePayloads.filter(
        (message) => !isSameSession(message.tabId) || hasCalculatedField(message.data)
      );
      handleLiveUpdates(addPayloads, updatePayloadsFiltered, deletePayloads);
    },
    [handleLiveUpdates]
  );

  const rtbeQueue = useRTBEQueue(handleLiveUpdatesLocal, debounceTime);

  const handleOnMessage: MessageCallback<IRTBERecordMessage> = useCallback(
    (message) => {
      const isSameSessionCheck = isSameSession(message.tabId);
      if (isDeleteOperation(message) && !isSameSessionCheck) {
        rtbeQueue.delete(message);
      } else if (
        (isRestoredOperation(message) || isCreatedOperation(message)) &&
        !isSameSessionCheck
      ) {
        rtbeQueue.add(message);
      } else if (isUpdatedOperation(message)) {
        rtbeQueue.update(message);
      }
    },
    [rtbeQueue]
  );

  const objectsToListen = useMemo(
    () => [
      {
        objectCode: 'RECORD',
        filter: {
          operation: '*',
          fieldName: 'parentId',
          fieldValue: recordTypeId,
        },
      },
    ],
    [recordTypeId]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const closeCallback = useCallback(() => {}, []);
  const connectionOptions = getIsInGenStudio()
    ? {
        adobeIOConfig: {
          ...getAdobeIOConfigForGenstudio(),
          subdomain: getAdobeIOConfigForGenstudio().subdomain || '',
        },
      }
    : { wfImsOrgId: currentUser.wfImsOrgId || '' };

  return useRtbeEvents(
    RTBE_TEAM,
    viewName,
    handleOnMessage,
    closeCallback,
    objectsToListen,
    connectionOptions
  );
};
