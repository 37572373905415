import { useCallback, useEffect, useRef } from 'react';
import { PagePresenceCallback } from '@wf-mfe/event-stream/types/page-presence-callback';
import { closeConnection, getConnection } from '@wf-mfe/event-stream';
import { RtbeConnection } from '@wf-mfe/event-stream/rtbe-connection';
import { ComponentPresenceCallback } from '@wf-mfe/event-stream/types/component-presence-callback';
import { useSelector } from '@store';

import { useRecordTypeData } from '../useRecordTypeData/useRecordTypeData';
export const useGetPresenceData = () => useSelector((store) => store.presenceRTBE);

export const usePresenceConnection = () => {
  const { current } = useRef<RtbeConnection<unknown>>(
    getConnection(
      'maestro',
      'maestro-record-type',
      () => void 0,
      () => void 0
    )
  );

  const handleCloseConnection = useCallback(() => closeConnection(current), [current]);

  return { connection: current, handleCloseConnection };
};

export const usePagePresence = (
  rtbeConnection: RtbeConnection<unknown>,
  presenceCallback?: PagePresenceCallback
) => {
  const { recordType } = useRecordTypeData();

  useEffect(() => {
    if (!recordType?.id || !rtbeConnection) {
      return;
    }

    if (presenceCallback) {
      rtbeConnection.stopPagePresence();
      rtbeConnection.startPagePresence(recordType.id, presenceCallback);
    }
  }, [presenceCallback, recordType.id, rtbeConnection]);
};

export const useComponentPresence = (
  rtbeConnection: RtbeConnection<unknown>,
  presenceCallback: ComponentPresenceCallback,
  recordTypeId?: string
) => {
  // Use the hook unconditionally
  const recordTypeData = useRecordTypeData();

  // Determine recordType based on the presence of recordTypeId
  const recordType = recordTypeId ? { id: recordTypeId } : recordTypeData?.recordType;

  useEffect(() => {
    if (!recordType?.id || !rtbeConnection) {
      return;
    }

    if (presenceCallback) {
      rtbeConnection.startComponentPresence(recordType.id, presenceCallback);
    }
  }, [presenceCallback, recordType.id, rtbeConnection]);
};
